import { FuseNavigationItem } from '@fuse/components/navigation';
import { EAdminPermission, EHospivillePermission } from '@mapuilabs/hv-interfaces';

export const navigation: FuseNavigationItem[] = [
    {
        id: 'admin',
        type: 'group',
        children: [
            {
                id: 'admin.establishment',
                type: 'collapsable',
                icon: 'heroicons_outline:office-building',
                meta: {
                    permissions: [EAdminPermission.Establishment_Read]
                },
                children: [
                    {
                        id: 'admin.hospital',
                        type: 'basic',
                        link: '/admin/hospital',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.pharmacy',
                        type: 'basic',
                        link: '/admin/pharmacy',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.office',
                        type: 'basic',
                        link: '/admin/office',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    }
                ]
            },
            {
                id: 'admin.users',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: '/admin/users',
                meta: {
                    permissions: [EAdminPermission.User_Read]
                }
            },
            {
                id: 'admin.plans',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: '/admin/plans',
                meta: {
                    permissions: [EAdminPermission.Plan_Read]
                }
            },
            {
                id: 'admin.roles',
                type: 'basic',
                icon: 'heroicons_outline:briefcase',
                link: '/admin/roles',
                meta: {
                    permissions: [EAdminPermission.Role_Read]
                }
            },
            {
                id: 'admin.actions',
                type: 'basic',
                icon: 'heroicons_outline:clock',
                link: '/admin/actions',
                meta: {
                    permissions: [EAdminPermission.Action_Read]
                }
            },
            {
                id: 'admin.custom-forms',
                type: 'basic',
                icon: 'heroicons_outline:document-text',
                link: '/admin/custom-forms',
                meta: {
                    permissions: [EAdminPermission.CustomForms_Read]
                }
            },
            {
                id: 'admin.release-notes',
                type: 'basic',
                icon: 'heroicons_outline:adjustments',
                link: '/admin/release-notes',
                meta: {
                    permissions: [EAdminPermission.ReleaseNote_Read]
                }
            },
            {
                id: 'admin.tags',
                type: 'basic',
                icon: 'feather:tag',
                link: '/admin/tags',
                meta: {
                    permissions: [EAdminPermission.Tag_Read]
                }
            },
            {
                id: 'admin.sandbox',
                type: 'basic',
                icon: 'feather:codesandbox',
                link: '/admin/sandbox',
                meta: {
                    permissions: [EAdminPermission.Action_Read]
                }
            },
            {
                id: 'admin.statistics',
                type: 'collapsable',
                icon: 'heroicons_outline:chart-bar',
                meta: {
                    permissions: [EAdminPermission.Establishment_Read]
                },
                children: [
                    {
                        id: 'admin.medrec',
                        type: 'basic',
                        link: '/admin/stats/medrec',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.octave',
                        type: 'basic',
                        link: '/admin/stats/octave',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.passerelle',
                        type: 'basic',
                        link: '/admin/stats/passerelle',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.shared-medication-review',
                        type: 'basic',
                        link: '/admin/stats/shared-medication-review',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.exchange',
                        type: 'basic',
                        link: '/admin/stats/exchange',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.exchange-recipients',
                        type: 'basic',
                        link: '/admin/stats/exchange-recipients',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    },
                    {
                        id: 'admin.transfer',
                        type: 'basic',
                        link: '/admin/stats/transfer',
                        meta: {
                            permissions: [EAdminPermission.Establishment_Read]
                        }
                    }
                ]
            },
            {
                id: 'admin.external-actors',
                type: 'basic',
                icon: 'heroicons_outline:share',
                link: '/admin/external-actors',
                meta: {
                    permissions: [EAdminPermission.Establishment_Read]
                }
            },
            {
                id: 'admin.api-logs',
                type: 'basic',
                icon: 'heroicons_outline:chip',
                link: '/admin/api-logs',
                meta: {
                    permissions: [EAdminPermission.Action_Read]
                }
            }
        ]
    },
    {
        id: 'pathways',
        type: 'group',
        children: [
            {
                id: 'pathways.list',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/pathways/list',
                meta: {
                    permissions: [EHospivillePermission.Pathway_Read]
                }
            },

            {
                id: 'pathways.passerelle-list',
                type: 'basic',
                icon: 'heroicons_outline:beaker',
                link: '/pathways/passerelle',
                meta: {
                    permissions: [EHospivillePermission.Pathway_Passerelle_Access]
                }
            },
            {
                id: 'pathways.octave-list',
                type: 'basic',
                icon: 'heroicons_outline:beaker',
                link: '/pathways/octave',
                meta: {
                    permissions: [EHospivillePermission.Pathway_Read]
                }
            },
            {
                id: 'pathways.export',
                type: 'basic',
                icon: 'heroicons_outline:download',
                link: '/pathways/export',
                meta: {
                    permissions: [EHospivillePermission.Pathway_Coordinate]
                }
            }
        ]
    },
    {
        id: 'pharmaceutical',
        type: 'group',
        children: [
            {
                id: 'pathways.shared-medication-review-list',
                type: 'basic',
                icon: 'heroicons_outline:clipboard',
                link: '/pathways/shared-medication-review',
                meta: {
                    permissions: [EHospivillePermission.Pathway_BPM_Access]
                }
            }
        ]
    },
    {
        id: 'patients',
        type: 'group',
        children: [
            {
                id: 'patients',
                type: 'basic',
                icon: 'heroicons_solid:user-group',
                link: '/patients',
                meta: {
                    permissions: [EHospivillePermission.Patient_Read]
                }
            },
            {
                id: 'patients.transferred',
                type: 'basic',
                icon: 'transfer_within_a_station',
                link: '/patients-transferred',
                meta: {
                    permissions: [EHospivillePermission.Patient_Read]
                }
            }
        ]
    },
    {
        id: 'exchanges',
        type: 'group',
        children: [
            {
                id: 'exchanges.sent',
                type: 'basic',
                icon: 'heroicons_solid:chat-alt-2',
                link: '/exchanges/sent',
                meta: {
                    permissions: [EHospivillePermission.ExchangeEvent_Read]
                }
            },
            {
                id: 'exchanges.received',
                type: 'basic',
                icon: 'heroicons_outline:inbox-in',
                link: '/exchanges/received',
                meta: {
                    permissions: [EHospivillePermission.ExchangeEvent_Read]
                }
            }
        ]
    },
    {
        id: 'dashboard',
        type: 'group',
        children: [
            {
                id: 'dashboard.inbox',
                type: 'basic',
                icon: 'mat_solid:inbox',
                link: '/dashboard/inbox',
                meta: {
                    permissions: [EHospivillePermission.Inbox]
                }
            },
            {
                id: 'dashboard.stats',
                type: 'basic',
                icon: 'mat_solid:analytics',
                link: '/dashboard/statistics',
                meta: {
                    permissions: [EHospivillePermission.Stats_Export]
                }
            }
        ]
    },
    {
        id: 'settings',
        type: 'group',
        children: [
            {
                id: 'settings.user',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: '/settings/user'
            },
            {
                id: 'settings.establishment',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: '/settings/establishment',
                meta: {
                    permissions: [
                        EHospivillePermission.EstablishmentService_Read,
                        EHospivillePermission.EstablishmentSettings_Informations
                    ]
                }
            },
            {
                id: 'settings.hospiville.knowledgebase',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'https://help.hospiville.fr/?wtlwp_token=350910e018a21c3eca04f4f230a5b8c319c479d5ed2c14cabc6977d61b43599f155335d655f8e3e484db4acaf7fa3c66243ed551576500df9646fa181aa39744',
                target: '_target',
                externalLink: true,
                meta: {
                    permissions: [EHospivillePermission.Pathway_Read]
                }
            },
            {
                id: 'settings.release-notes',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/settings/release-notes'
            },
            {
                id: 'settings.changelog',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/settings/changelog',
                meta: {
                    permissions: [EAdminPermission.ReleaseNote_Read]
                }
            }
        ]
    }
];
