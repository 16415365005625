import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { AvatarModule } from '@shared/modules/avatar/avatar.module';

import { UserMenuComponent } from '@core/main/common/user-menu/user-menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [UserMenuComponent],
    imports: [
        CommonModule,
        TranslocoCoreModule,

        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,

        AvatarModule,
        MatTooltipModule
    ],
    exports: [UserMenuComponent]
})
export class UserMenuModule {}
