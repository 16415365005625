import { Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { FUSE_VERSION } from '@fuse/version';
import { UserService } from '@core/user/user.service';
import { NavigationService } from '@core/navigation/navigation.service';
import { EHvStatus, Profile, User } from '@mapuilabs/hv-interfaces';
import { SocketService } from '@services/socket/socket.service';
import * as Sentry from '@sentry/angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IdleModalComponent } from '@shared/components/idle-modal/idle-modal.component';
import { UsersCrudService } from '@services/users/users-crud.service';
import { first } from 'rxjs';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';

@Component({
    selector: 'main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
    idleModal: MatDialogRef<IdleModalComponent> | undefined;
    termsAccepted = false;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        public _navigationService: NavigationService,
        private _fuseConfigService: FuseConfigService,
        private _renderer2: Renderer2,
        private _userService: UserService,
        private _socketService: SocketService,
        private _idle: Idle,
        private _matDialog: MatDialog,
        private _userCrudService: UsersCrudService,
        private _snackbarService: SnackbarService
    ) {
        this._idle.setIdle((this._userService.profile.user as User).config?.idleTime || 1620); // 27 minutes
        this._idle.setTimeout(180); // 3 minutes
        this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this._idle.onIdleStart.subscribe(() => {
            this.idleModal = this._matDialog.open(IdleModalComponent, { minWidth: '60%' });
        });
        this._idle.onIdleEnd.subscribe(() => {
            this.idleModal?.close();
        });
    }

    ngOnInit(): void {
        if (this._userService.profile.user) {
            const user = this._userService.profile.user as User;
            if (user.config) {
                this._fuseConfigService.config = { scheme: user.config.scheme };
            }
        }

        this._idle.watch();
        this.termsAccepted = Boolean((this._userService.profile.user as User).CGU);
        this._socketService.connect();

        // Set the app version
        this._renderer2.setAttribute(this._document.querySelector('[ng-version]'), 'fuse-version', FUSE_VERSION);

        Sentry.getCurrentScope().setUser({
            id: (this._userService.profile as Profile)._id.toString(),
            username: (this._userService.profile.user as User).fullName,
            email: (this._userService.profile.user as User).email
        });
    }

    validateTermsOfUse() {
        const updatedUser = this._userService.profile.user as User;
        updatedUser.CGU = new Date();
        this._userCrudService
            .updateTermsOfUse(updatedUser._id, updatedUser.CGU)
            .pipe(first())
            .subscribe({
                next: (user) => {
                    if (user.CGU) {
                        this.termsAccepted = true;
                    } else {
                        this._snackbarService.show(EHvStatus.RejectByServer);
                    }
                },
                error: () => {
                    this._snackbarService.show(EHvStatus.RejectByServer);
                }
            });
    }
}
