.profile-herder {
    // (avatar height + white border) / 2
    padding-bottom: calc((72px + 8px) / 2);
}

.avatar {
    // Profile height - (avatar height + white border) / 2
    top: calc(104px - (72px + 8px) / 2);

    // 50% - avatar-container / 2
    left: calc(50% - 80px / 2);
}
