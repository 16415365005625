import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { AuthUtils } from '@core/auth/auth.utils';
import { UserService } from '@core/user/user.service';
import { FuseConfigService } from '@fuse/services/config';
import { Establishment, IECPSUser, IHealthProActivity, Profile, User, genericFactory } from '@mapuilabs/hv-interfaces';
import { ProfilesCrudService } from '@services/profiles/profiles-crud.service';
import { Observable, map, of } from 'rxjs';

export const SelectProfilesResolver: ResolveFn<Profile[]> = (
    route: ActivatedRouteSnapshot
): Observable<Profile[]> | undefined => {
    const jwt = route.paramMap.get('availableProfilesJWT');

    if (AuthUtils.isTokenExpired(jwt) === false) {
        const { availableProfiles, user } = AuthUtils.decodeToken(jwt) as {
            availableProfiles: Profile[];
            user: User;
        };

        if (user.CGU) {
            user.CGU = new Date(user.CGU);
        }

        if (availableProfiles) {
            return of(
                availableProfiles.map((profile: Profile) => {
                    profile.user = user;
                    return new Profile(profile);
                })
            );
        }
    }
};

export const SelectActivityResolver: ResolveFn<{ activities: IHealthProActivity[]; user: IECPSUser }> = (
    route: ActivatedRouteSnapshot
): Observable<{ activities: IHealthProActivity[]; user: IECPSUser }> | undefined => {
    const jwt = route.paramMap.get('jwt');

    if (AuthUtils.isTokenExpired(jwt) === false) {
        const { activities, user } = AuthUtils.decodeToken(jwt) as {
            activities: IHealthProActivity[];
            user: IECPSUser;
        };

        if (activities && user) {
            return of({ activities, user, raw: jwt });
        }
    }
};

export const FirstConnexionEmailResolver: ResolveFn<{ establishment: Establishment; user: IECPSUser }> = (
    route: ActivatedRouteSnapshot
): Observable<{ establishment: Establishment; user: IECPSUser }> | undefined => {
    const jwt = route.paramMap.get('jwt');

    if (AuthUtils.isTokenExpired(jwt) === false) {
        const { establishment, user } = AuthUtils.decodeToken(jwt) as {
            establishment: Establishment;
            user: IECPSUser;
        };

        if (establishment && user) {
            return of({ establishment: genericFactory<Establishment>(establishment), user });
        }
    }
};

export const ChangeProfilesResolver: ResolveFn<Profile[]> = (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    _profilesCrudService: ProfilesCrudService = inject(ProfilesCrudService),
    _userService: UserService = inject(UserService)
): Observable<Profile[]> | undefined => {
    const profile = _userService.profile;
    let _user: User;

    // Set user with type guard
    if (profile.user instanceof User) {
        _user = profile.user;
    } else {
        throw new Error('Type error: profile.user should be an instance of User');
    }
    return _profilesCrudService.readByUserId(_user._id).pipe(
        map((profiles) => {
            profiles.forEach((profile) => (profile.user = new User(_user)));
            return profiles;
        })
    );
};

export const ThemesResolver: ResolveFn<void> = (
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    _fuseConfigService: FuseConfigService = inject(FuseConfigService),
    _userService: UserService = inject(UserService)
): void => {
    _fuseConfigService.config = { theme: _userService.profile.isAdmin ? 'theme-rose' : 'theme-default' };
};
