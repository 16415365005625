import { Injectable, Injector } from '@angular/core';

import { EFilterType, IPaginationOptions, IResponsePaginationListType, Notification } from '@mapuilabs/hv-interfaces';
import { CrudTemplateService } from '@shared/crud-service.class';
import { first } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsCrudService extends CrudTemplateService<Notification<any>> {
    constructor(protected _injector: Injector) {
        super(_injector, 'notifications', Notification<any>);
    }

    public countNotifications(params?: { read: boolean }): Promise<number> {
        const httpParams: { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> } = {
            pagination: JSON.stringify(<IPaginationOptions>{
                page: 1,
                size: 1
            })
        };

        if (params) {
            httpParams.filters = JSON.stringify([{ attribute: 'read', type: EFilterType.VALUE, value: 'false' }]);
        }

        return new Promise((resolve, reject) => {
            this._httpClient
                .get<IResponsePaginationListType<Notification<any>>>(this._endpoint, {
                    params: httpParams
                })
                .pipe(first())
                .subscribe({
                    next: (items) => {
                        resolve(items.pagination.total);
                    },
                    error: reject
                });
        });
    }
}
