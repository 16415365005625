// Angular modules
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { AuthModule } from '@core/auth/auth.module';
import { IconsModule } from '@core/icons/icons.module';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';
import { PaginatorIntl } from '@core/paginator-intl';
import { APIInterceptor } from './http/http.interceptor';
import { KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';

// Components (singleton)

@NgModule({
    imports: [
        AuthModule,
        IconsModule,
        TranslocoCoreModule,
        KeyValuePipe,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule
    ],
    declarations: [
        // Components (singleton)

        LoadingDialogComponent
    ],
    exports: [
        // Components (singleton)
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: PaginatorIntl },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
