import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';

// FuseModule
import { provideFuse } from '@fuse';
// import { FuseConfigModule } from '@fuse/services/config';

import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { appConfig } from '@core/config/app.config';
import { CoreModule } from '@core/core.module';
import { MainModule } from '@core/main/main.module';
import { AuthComponent } from '@modules/auth/auth.component';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { VidalModule } from '@modules/vidal/vidal.module';
import * as Sentry from '@sentry/angular';
import { MarkdownModule } from 'ngx-markdown';

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [AppComponent, AuthComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        MarkdownModule.forRoot({ loader: HttpClient }),
        // Core module of your applicationApp
        CoreModule,
        // Layout module of your application
        MainModule,
        VidalModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: { pageSizeOptions: [10, 25, 50, 100], showFirstLastButtons: true }
        },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500, hideDelay: 300 } },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                disableClose: true
            } as MatDialogConfig
        },
        provideFuse({
            fuse: appConfig
        }),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}

registerLocaleData(localeFr);
