<div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
>
    <div class="flex items-center pr-2 space-x-2">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon svgIcon="heroicons_outline:menu"></mat-icon>
        </button>

        <!-- Navigation appearance toggle button -->
        <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
            <mat-icon svgIcon="heroicons_outline:switch-horizontal"></mat-icon>
        </button>

        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <page-header-breadcrumbs></page-header-breadcrumbs>
        </div>
    </div>

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-2">
        <language></language>
        {{ activeEstablishment?.establishmentName }}
        <!--fuse-fullscreen></fuse-fullscreen>-->
        <profile-notifications></profile-notifications>
        <user-menu></user-menu>
    </div>
</div>
