<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="_navigationService.navigationAppearance$ | async"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="_navigationService.navigation$ | async"
    [opened]="_navigationService.navigationOpened$ | async"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img
                class="p-2 w-30 cursor-pointer"
                src="assets/images/logo/hospiville.svg"
                alt="Logo image"
                [ngClass]="{ 'home-logo-disabled': !canGoHome }"
                (click)="navigateToHome()"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>
