import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable, OnDestroy } from '@angular/core';
import { translate, Translation, TranslocoService } from '@jsverse/transloco';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl implements OnDestroy {
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(private _translocoService: TranslocoService) {
        super();
        this._translocoService.langChanges$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((lang: string) => {
                    return this._getTranslations(lang);
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private _getTranslations(lang: string): Observable<void | Translation> {
        return this._translocoService.load(lang).pipe(
            first(),
            tap(() => {
                this.itemsPerPageLabel = undefined;
                this.firstPageLabel = translate('LIST.PAGINATOR.FIRST_PAGE', {}, lang);
                this.lastPageLabel = translate('LIST.PAGINATOR.LAST_PAGE', {}, lang);
                this.previousPageLabel = translate('LIST.PAGINATOR.PREVIOUS_PAGE', {}, lang);
                this.nextPageLabel = translate('LIST.PAGINATOR.NEXT_PAGE', {}, lang);
            })
        );
    }

    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };
}
