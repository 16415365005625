import { MainComponent } from '@core/main/main.component';
import { NgIdleModule } from '@ng-idle/core';
import { NavigationModule } from '@core/navigation/navigation.module';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToolbarModule } from '@core/main/toolbar/toolbar.module';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';

@NgModule({
    declarations: [MainComponent],
    exports: [MainComponent],
    imports: [
        TranslocoCoreModule,
        SharedModule,
        NgIdleModule.forRoot(),
        RouterModule,
        MatSnackBarModule,
        NavigationModule,
        ToolbarModule,
        FuseLoadingBarComponent,
        MatButtonModule
    ],
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: ['auth', 'navigation'] },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class MainModule {}
