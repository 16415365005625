import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Load fr locale for day and month translations
import 'dayjs/locale/fr';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const subdomain = window?.location?.hostname?.split('.')[0];
let environmentName: 'production' | 'preproduction' | 'development';
let dsn = 'https://dcf81d0b9ae146d0a69e054442b1b70a@sentry.mapui.fr/5';

if (subdomain === 'app') {
    environmentName = 'production';
} else if (subdomain === 'preprod') {
    environmentName = 'preproduction';
} else {
    environmentName = 'development';
    dsn = null;
}

Sentry.init({
    dsn,
    integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] }), Sentry.browserTracingIntegration()],
    environment: environmentName,
    enabled: environment.production === true,

    tracesSampleRate: 1.0
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
