import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Profile } from '@mapuilabs/hv-interfaces';
import { UserService } from '@core/user/user.service';
import { NotificationsCrudService } from '@services/notifications/notifications-crud.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationsService } from '@services/notifications/notifications.service';

@Component({
    selector: 'profile-notifications',
    templateUrl: './profile-notifications.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProfileNotificationsComponent implements OnInit, OnDestroy {
    private socketSubscription: Subscription;

    public profile: Profile;
    public nbNotif: number;

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _notificationCrudService: NotificationsCrudService,
        private _notificationService: NotificationsService
    ) {
        this.profile = this._userService.profile;
        this.fetchNotifications();
    }

    ngOnInit() {
        this._notificationService.listen();
        this.socketSubscription = this._notificationService.notificationsChanged.subscribe(() => {
            this.fetchNotifications();
        });
    }

    ngOnDestroy() {
        this.socketSubscription?.unsubscribe();
    }

    async fetchNotifications() {
        try {
            const total = await this._notificationCrudService.countNotifications({ read: false });
            this.nbNotif = total > 0 ? total : null;
        } catch (error) {
            console.error(error);
        }
    }

    goToNotificationsPage() {
        this._router.navigate(['notifications']);
    }
}
