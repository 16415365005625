import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { NavigationService } from '@core/navigation/navigation.service';
import { UserService } from '@core/user/user.service';
import { EHospivillePermission } from '@mapuilabs/hv-interfaces';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _navigationService: NavigationService,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: ({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
                if (this.isScreenSmall && this._navigationService.navigationOpened) {
                    this._navigationService.navigationOpened = false;
                }
            }
        });

        // Subscribe to NavigationEnd event
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        navigation.openedChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (val) => {
                if (!val) {
                    this._navigationService.navigationOpened = val;
                }
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    navigateToHome(): void {
        if (this.canGoHome) {
            this._router.navigate(['']).then();
        }
    }

    public get canGoHome(): boolean {
        return (
            this._userService.hasPermission(EHospivillePermission.Patient_Read) &&
            !this._userService.hasPermission(EHospivillePermission.Pathway_BPM_Access)
        );
    }
}
