import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { switchMap } from 'rxjs/operators';
import { AuthUtils } from '@core/auth/auth.utils';

@Injectable({
    providedIn: 'root'
})
export class ExternalSignAuthGuard {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const token = route.params['jwt'];
        this._authService.accessToken = token;
        const decodeToken: { pathway: string } = AuthUtils.decodeToken(token);

        if (decodeToken.pathway) {
            this._router.navigate([`pathways/list/${decodeToken.pathway}`]).then();
        }
        const redirectUrl = decodeToken.pathway ? `pathways/list/${decodeToken.pathway}` : '/';

        return this._check(redirectUrl);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                // If the user is not authenticated...
                if (!authenticated) {
                    // Redirect to the sign-in page
                    this._router.navigate(['sign-in'], { queryParams: { redirectURL } }).then();

                    // Prevent the access
                    return of(false);
                }
                this._router.navigate([redirectURL]).then();

                // Allow the access
                return of(true);
            })
        );
    }
}
