import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileNotificationsComponent } from './profile-notifications.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationsService } from '@services/notifications/notifications.service';
import { PatientNamePipe } from '@shared/pipes/patient-name.pipe';
import { PathwayNamePipe } from '@shared/pipes/pathway-name.pipe';

@NgModule({
    declarations: [ProfileNotificationsComponent],
    imports: [CommonModule, TranslocoCoreModule, MatButtonModule, MatBadgeModule, MatIconModule, MatTooltipModule],
    exports: [ProfileNotificationsComponent],
    providers: [NotificationsService, PatientNamePipe, PathwayNamePipe]
})
export class ProfileNotificationsModule {}
