import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { AuthUtils } from '@core/auth/auth.utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {}

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        if (req.url.startsWith('https://')) {
            return next.handle(req);
        }

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the users out from the app.
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (
                    (error instanceof HttpErrorResponse && error.status === 401) ||
                    (error instanceof HttpErrorResponse &&
                        error.error.statusCode === 403 &&
                        error.error.message == 'Old JWT')
                ) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app after next tick so it doesn't loop on the page where the error occurred
                    window.requestAnimationFrame(() => {
                        location.reload();
                    });
                }

                // Forbidden access
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 403 &&
                    error.error.message !== 'Invalid code' &&
                    this._authService.isAuthenticated
                ) {
                    const homePage = this._authService.getHomePage();
                    // Redirect to the home-page according to role
                    this._router.navigate([homePage]).then();
                }

                return throwError(() => error);
            })
        );
    }
}
