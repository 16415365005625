import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let url = req.url;

        if (url.startsWith('/')) {
            url = url.substr(1);
        }

        let apiReq = req.clone();

        if (!url.startsWith('assets') && !url.startsWith('https://')) {
            apiReq = req.clone({ url: `${environment.apiUrl}/${url}` });
        }
        return next.handle(apiReq);
    }
}
