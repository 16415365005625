import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VidalSecurisationComponent } from './vidal-securisation/vidal-securisation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GroupByPipe, NgPipesModule } from 'ngx-pipes';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';
import { FuseAlertComponent } from '@fuse/components/alert';

@NgModule({
    declarations: [VidalSecurisationComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        NgPipesModule,
        FuseAlertComponent,
        TranslocoCoreModule
    ],
    providers: [GroupByPipe]
})
export class VidalModule {}
