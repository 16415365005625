import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UserMenuModule } from '../common/user-menu/user-menu.module';
import { SharedModule } from '@shared/shared.module';

import { ToolbarComponent } from './toolbar.component';
import { LanguageModule } from '@core/main/common/language/language.module';
import { ProfileNotificationsModule } from '../common/profile-notifications/profile-notifications.module';

@NgModule({
    declarations: [ToolbarComponent],
    exports: [ToolbarComponent],
    imports: [
        SharedModule,
        RouterModule,
        FuseDrawerComponent,
        FuseFullscreenComponent,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        LanguageModule,
        UserMenuModule,
        ProfileNotificationsModule
    ]
})
export class ToolbarModule {}
