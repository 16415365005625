import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { NavigationComponent } from '@core/navigation/navigation.component';
import { TranslocoCoreModule } from '@core/transloco/transloco.module';

@NgModule({
    declarations: [NavigationComponent],
    imports: [MatIconModule, SharedModule, MatButtonModule, FuseVerticalNavigationComponent, TranslocoCoreModule],
    exports: [NavigationComponent]
})
export class NavigationModule {}
