import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { AvailableLangs, TranslocoService } from '@jsverse/transloco';
import { FuseNavigationService } from '@fuse/components/navigation';
import { UserService } from '@core/user/user.service';
import { IUserConfig, User } from '@mapuilabs/hv-interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'language',
    templateUrl: './language.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'language'
})
export class LanguageComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: Record<string, string>;
    isAdmin: boolean;
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _userService: UserService
    ) {
        this.isAdmin = _userService.profile?.isAdmin;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (activeLang) => {
                // Get the active lang
                this.activeLang = activeLang;
            }
        });

        if (this._userService.profile.user) {
            const user = this._userService.profile.user as User;
            if (user.config) {
                this._translocoService.setActiveLang(user.config.defaultLang);
            }
        }

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            en: 'us',
            fr: 'fr'
        };
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
        this._userService.saveConfig({ defaultLang: lang } as IUserConfig).subscribe({
            next: (updatedUser) => {
                this._userService.profile.user = updatedUser;
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): string {
        return item.id || index;
    }
}
