import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UserService } from '@core/user/user.service';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { translate, Translation, TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import {
    EHospivillePermission,
    EOctaveDepts,
    EPermissions,
    IUserConfig,
    Office,
    Pharmacy,
    User
} from '@mapuilabs/hv-interfaces';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { navigation } from '../../../navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService implements OnDestroy {
    private _navigation: BehaviorSubject<FuseNavigationItem[]> = new BehaviorSubject(null);
    public navigation$: Observable<FuseNavigationItem[]> = this._navigation.asObservable();

    private _navigationAppearance: BehaviorSubject<'default' | 'dense'> = new BehaviorSubject('dense');
    public navigationAppearance$: Observable<'default' | 'dense'> = this._navigationAppearance.asObservable();

    private _navigationOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public navigationOpened$: Observable<boolean> = this._navigationOpened.asObservable();

    private _unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        private _translocoService: TranslocoService,
        private _userService: UserService,
        private _fuseNavigationService: FuseNavigationService,
        @Inject(TRANSLOCO_SCOPE) public scope: string[]
    ) {
        if (this._userService.profile.user) {
            const user = this._userService.profile.user as User;
            if (user.config) {
                this._navigationOpened.next(user.config.sidenavOpened);
                this._navigationAppearance.next(user.config.sidenavAppearance);
            }
        }
        this._translocoService.langChanges$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((lang: string) => {
                    return this._load(lang);
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private _load(lang: string): Observable<void | Translation> {
        const activeLangPath = `${this.scope[1]}/${lang}`;
        return this._translocoService.load(activeLangPath).pipe(
            first(),
            tap(() => {
                this._translateNavigation(lang);
                this._refreshNavigation();
            })
        );
    }

    private _translateNavigation(lang: string): void {
        let newNav = navigation.map((nav) => {
            const navElemScope = `${this.scope[1]}.${nav.id.toUpperCase()}`;
            const newElem = nav;
            newElem.title = translate(`${navElemScope}.TITLE`, {}, lang);
            if (nav.type === 'group') {
                newElem.subtitle = translate(`${navElemScope}.SUBTITLE`, {}, lang);
                newElem.children.forEach((child) => {
                    const childScope = `${this.scope[1]}.${child.id.toUpperCase()}`;
                    child.title = translate(`${childScope}.TITLE`, {}, lang);
                    if (child.type === 'collapsable') {
                        child.children.forEach((child) => {
                            const childScope = `${this.scope[1]}.${child.id.toUpperCase()}`;
                            child.title = translate(`${childScope}.TITLE`, {}, lang);
                        });
                    }
                });
                newElem.children = this._filterByPermissions(newElem.children);
            }
            return newElem;
        });
        newNav = this._filterByPermissions(newNav);
        newNav = newNav.filter((elem) => {
            if (elem.type === 'group') {
                return elem.children && elem.children.length > 0;
            }
            return true;
        });

        this._navigation.next(newNav);
    }

    private _refreshNavigation(): void {
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        if (navComponent) {
            navComponent.refresh();
        }
    }

    private _filterByPermissions(array: Array<FuseNavigationItem>): Array<FuseNavigationItem> {
        return array.filter((elem: Omit<FuseNavigationItem, 'meta'> & { meta?: { permissions: EPermissions[] } }) => {
            if (!elem.meta?.permissions?.length) {
                // if no permissions are required, it's ok
                return true;
            }
            const hasPermission = elem.meta.permissions.some((perm: EPermissions) =>
                this._userService.hasPermission(perm)
            );
            if (elem.id === 'pathways.octave-list') {
                //Octave only
                const userEstab = this._userService.profile.establishment;
                return (
                    hasPermission &&
                    ((userEstab instanceof Pharmacy &&
                        (Object.values(EOctaveDepts) as string[]).includes(
                            (userEstab?.finessCode || '').substr(0, 2)
                        )) ||
                        this._userService.hasPermission(EHospivillePermission.Pathway_Coordinate))
                );
            }
            if (elem.id === 'pathways.list') {
                return (
                    hasPermission &&
                    !(this._userService.profile.establishment instanceof Office) &&
                    !this._userService.hasPermission(EHospivillePermission.Pathway_Coordinate) &&
                    !(this._userService.profile.establishment instanceof Pharmacy) // Remove this line if officines needs to have other pathway than Octave
                );
            }
            return hasPermission;
        });
    }

    public set navigationAppearance(val: 'default' | 'dense') {
        this._userService.saveConfig({ sidenavAppearance: val } as IUserConfig).subscribe({
            next: (updatedUser) => {
                this._userService.profile.user = updatedUser;
            },
            error: (err) => {
                console.error(err);
            }
        });
        this._navigationAppearance.next(val);
    }

    public get navigationAppearance(): 'default' | 'dense' {
        return this._navigationAppearance.value;
    }

    public set navigationOpened(val: boolean) {
        this._userService.saveConfig({ sidenavOpened: val } as IUserConfig).subscribe({
            next: (updatedUser) => {
                this._userService.profile.user = updatedUser;
            },
            error: (err) => {
                console.error(err);
            }
        });
        this._navigationOpened.next(val);
    }
}
