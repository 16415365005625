import { ActivatedRouteSnapshot, Route, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NoAuthGuard } from '@core/auth/guards/noAuth.guard';
import { MainComponent } from '@core/main/main.component';
import { AuthComponent } from '@modules/auth/auth.component';

import {
    ChangeProfilesResolver,
    SelectActivityResolver,
    SelectProfilesResolver,
    ThemesResolver
} from 'app/app.resolvers';
import { EAdminPermission, EHospivillePermission } from '@mapuilabs/hv-interfaces';
import { ExternalSignAuthGuard } from '@core/auth/guards/external-sign.guard. ts';
import { inject } from '@angular/core';

const defaultHome = '/home';

export const appRoutes: Route[] = [
    // Redirect empty path to '/home'
    { path: '', pathMatch: 'full', redirectTo: defaultHome },

    // After the users signs in, the sign in page will redirect the users to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the users to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: defaultHome },

    {
        path: 'redirect-external/:jwt',
        canActivate: [
            (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                inject(ExternalSignAuthGuard).canActivate(route, state)
        ],
        component: AuthComponent
    },
    // Auth routes for guests
    {
        path: '',
        canActivate: [
            (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(NoAuthGuard).canActivate(route, state)
        ],
        canActivateChild: [
            (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                inject(NoAuthGuard).canActivateChild(childRoute, state)
        ],
        component: AuthComponent,
        children: [
            {
                path: 'link-expired',
                loadChildren: () =>
                    import('@modules/auth/link-expired/link-expired.module').then((m) => m.LinkExpiredModule)
            },
            {
                path: 'auth-error',
                loadChildren: () => import('@modules/auth/error/auth-error.module').then((m) => m.AuthErrorModule)
            },
            {
                path: 'select-activity/:jwt',
                resolve: {
                    jwt: SelectActivityResolver
                },
                loadChildren: () =>
                    import('@modules/auth/select-activity/select-activity.module').then((m) => m.SelectActivityModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('@modules/auth/forgot-password/forgot-password.module').then(
                        (m) => m.AuthForgotPasswordModule
                    )
            },
            {
                path: 'deactivated-account',
                loadChildren: () =>
                    import('@modules/auth/deactivated-account/deactivated-account.module').then(
                        (m) => m.AuthDeactivatedAccountModule
                    )
            },
            {
                path: 'reset-password/:jwt',
                loadChildren: () =>
                    import('@modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('@modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule)
            },
            {
                path: 'sign-in/admin',
                loadChildren: () =>
                    import('@modules/auth/sign-in-admin/sign-in-admin.module').then((m) => m.AuthSignInAdminModule)
            },
            {
                path: 'select-profile/:availableProfilesJWT',
                resolve: {
                    profiles: SelectProfilesResolver
                },
                loadChildren: () =>
                    import('@modules/auth/select-profile/select-profile.module').then((m) => m.AuthSelectProfileModule)
            },
            {
                path: 'first-login/:jwt',
                loadChildren: () => import('@modules/auth/register/register.module').then((m) => m.AuthRegisterModule)
            },
            {
                path: 'sillage-error',
                loadChildren: () =>
                    import('@modules/sillage/error/sillage-error.module').then((m) => m.SillageErrorModule)
            }
        ]
    },

    // Auth routes for authenticated list
    {
        path: '',
        canActivate: [
            (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)
        ],
        canActivateChild: [(childRoute: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateChild(childRoute)],
        component: AuthComponent,
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('@modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule)
            },
            {
                path: 'change-profile',
                resolve: {
                    profiles: ChangeProfilesResolver
                },
                loadChildren: () =>
                    import('@modules/auth/select-profile/select-profile.module').then((m) => m.AuthSelectProfileModule)
            }
        ]
    },

    // Modules routes
    {
        path: '',
        data: {
            breadcrumb: '',
            disabled: false
        },
        canActivate: [
            (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)
        ],
        resolve: {
            theme: ThemesResolver
        },
        canActivateChild: [(childRoute: ActivatedRouteSnapshot) => inject(AuthGuard).canActivateChild(childRoute)],
        component: MainComponent,
        children: [
            // Admin routes
            {
                path: 'admin',
                data: {
                    breadcrumb: 'ADMIN',
                    disabled: true
                },
                children: [
                    // Hospital
                    {
                        path: 'hospital',
                        data: {
                            breadcrumb: 'ADMIN.HOSPITAL',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Establishment_Delete,
                                EAdminPermission.Establishment_Create,
                                EAdminPermission.Establishment_Read,
                                EAdminPermission.Establishment_Update
                            ]
                        },
                        loadChildren: () =>
                            import('@modules/admin/establishments/hospitals/hospitals.module').then(
                                (m) => m.HospitalModule
                            )
                    },
                    // Pharmacy
                    {
                        path: 'pharmacy',
                        data: {
                            breadcrumb: 'ADMIN.PHARMACY',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Establishment_Delete,
                                EAdminPermission.Establishment_Create,
                                EAdminPermission.Establishment_Read,
                                EAdminPermission.Establishment_Update
                            ]
                        },
                        loadChildren: () =>
                            import('@modules/admin/establishments/pharmacy/pharmacy.module').then(
                                (m) => m.PharmacyModule
                            )
                    },
                    // Office
                    {
                        path: 'office',
                        data: {
                            breadcrumb: 'ADMIN.OFFICE',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Establishment_Delete,
                                EAdminPermission.Establishment_Create,
                                EAdminPermission.Establishment_Read,
                                EAdminPermission.Establishment_Update
                            ]
                        },
                        loadChildren: () =>
                            import('@modules/admin/establishments/office/office.module').then((m) => m.OfficeModule)
                    },
                    // Users
                    {
                        path: 'users',
                        data: {
                            breadcrumb: 'ADMIN.USERS',
                            disabled: false,
                            permissions: [
                                EAdminPermission.User_Delete,
                                EAdminPermission.User_Create,
                                EAdminPermission.User_Read,
                                EAdminPermission.User_Update
                            ]
                        },
                        loadChildren: () => import('@modules/admin/users/users.module').then((m) => m.UsersModule)
                    },
                    // Plans
                    {
                        path: 'plans',
                        data: {
                            breadcrumb: 'ADMIN.PLANS',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Plan_Delete,
                                EAdminPermission.Plan_Create,
                                EAdminPermission.Plan_Read,
                                EAdminPermission.Plan_Update
                            ]
                        },
                        loadChildren: () => import('@modules/admin/plans/plans.module').then((m) => m.AdminPlansModule)
                    },
                    // Roles
                    {
                        path: 'roles',
                        data: {
                            breadcrumb: 'ADMIN.ROLES',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Role_Delete,
                                EAdminPermission.Role_Create,
                                EAdminPermission.Role_Read,
                                EAdminPermission.Role_Update
                            ]
                        },
                        loadChildren: () => import('@modules/admin/roles/roles.module').then((m) => m.RolesModule)
                    },
                    // Actions
                    {
                        path: 'actions',
                        data: {
                            breadcrumb: 'ADMIN.ACTIONS',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Cron_Read,
                                EAdminPermission.Cron_Create,
                                EAdminPermission.Cron_Update
                            ]
                        },
                        loadChildren: () => import('@modules/admin/actions/actions.module').then((m) => m.ActionsModule)
                    },
                    // Custom forms
                    {
                        path: 'custom-forms',
                        data: {
                            breadcrumb: 'ADMIN.CUSTOM-FORMS',
                            disabled: false,
                            permissions: [
                                EAdminPermission.CustomForms_Read,
                                EAdminPermission.CustomForms_Create,
                                EAdminPermission.CustomForms_Update
                            ]
                        },
                        loadChildren: () =>
                            import('@modules/admin/custom-forms-config/custom-forms-config.module').then(
                                (m) => m.AdminCustomFormsConfigModule
                            )
                    },
                    // Configuration panel
                    {
                        path: 'release-notes',
                        data: {
                            breadcrumb: 'ADMIN.RELEASE-NOTES',
                            disabled: false,
                            permissions: [
                                EAdminPermission.ReleaseNote_Read,
                                EAdminPermission.ReleaseNote_Create,
                                EAdminPermission.ReleaseNote_Update
                            ]
                        },
                        loadChildren: () =>
                            import('@modules/admin/release-notes/release-notes.module').then(
                                (m) => m.AdminReleaseNotesModule
                            )
                    },
                    // External-Actors
                    {
                        path: 'external-actors',
                        data: {
                            breadcrumb: 'ADMIN.EXTERNAL-ACTORS',
                            disabled: false,
                            permissions: [
                                EAdminPermission.Establishment_Delete,
                                EAdminPermission.Establishment_Create,
                                EAdminPermission.Establishment_Read,
                                EAdminPermission.Establishment_Update
                            ]
                        },
                        loadChildren: () =>
                            import('@modules/admin/external-actors/external-actors.module').then(
                                (m) => m.ExternalActorsModule
                            )
                    },
                    // Tags
                    {
                        path: 'tags',
                        data: {
                            breadcrumb: 'ADMIN.TAGS',
                            disabled: false,
                            permissions: [EAdminPermission.Tag_Read]
                        },
                        loadChildren: () => import('@modules/admin/tags/tags.module').then((m) => m.TagsModule)
                    },
                    // Sandbox
                    {
                        path: 'sandbox',
                        data: {
                            breadcrumb: 'ADMIN.SANDBOX',
                            disabled: false,
                            permissions: [EAdminPermission.Role_Delete]
                        },
                        loadChildren: () => import('@modules/admin/sandbox/sandbox.module').then((m) => m.SandboxModule)
                    },
                    {
                        path: 'stats',
                        data: {
                            breadcrumb: 'ADMIN.STATISTICS',
                            disabled: true,
                            permissions: [EAdminPermission.Role_Delete]
                        },
                        children: [
                            // Medrec
                            {
                                path: 'medrec',
                                data: {
                                    breadcrumb: 'ADMIN.MEDREC',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import('@modules/admin/statistics/medrec/medrec.module').then((m) => m.MedrecModule)
                            },
                            // Octave
                            {
                                path: 'octave',
                                data: {
                                    breadcrumb: 'ADMIN.OCTAVE',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import('@modules/admin/statistics/octave/octave.module').then((m) => m.OctaveModule)
                            },
                            // Passerelle
                            {
                                path: 'passerelle',
                                data: {
                                    breadcrumb: 'ADMIN.PASSERELLE',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import('@modules/admin/statistics/passerelle/passerelle.module').then(
                                        (m) => m.PasserelleModule
                                    )
                            },
                            // Shared medication review (BPM)
                            {
                                path: 'shared-medication-review',
                                data: {
                                    breadcrumb: 'ADMIN.SHARED-MEDICATION-REVIEW',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import(
                                        '@modules/admin/statistics/shared-medication-review/shared-medication-review.module'
                                    ).then((m) => m.sharedMedicationReviewModule)
                            },
                            // Exhange
                            {
                                path: 'exchange',
                                data: {
                                    breadcrumb: 'ADMIN.EXCHANGE',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import('@modules/admin/statistics/exchange/exchange.module').then(
                                        (m) => m.ExchangeModule
                                    )
                            },
                            {
                                path: 'exchange-recipients',
                                data: {
                                    breadcrumb: 'ADMIN.EXCHANGE-RECIPIENTS',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import('@modules/admin/statistics/exchange/exchange.module').then(
                                        (m) => m.ExchangeModule
                                    )
                            },
                            // Transfer
                            {
                                path: 'transfer',
                                data: {
                                    breadcrumb: 'ADMIN.TRANSFER',
                                    disabled: false
                                },
                                loadChildren: () =>
                                    import('@modules/admin/statistics/transfer/transfer.module').then(
                                        (m) => m.TransferModule
                                    )
                            }
                        ]
                    },
                    {
                        path: 'api-logs',
                        data: {
                            breadcrumb: 'ADMIN.API-LOGS',
                            disabled: false,
                            permissions: [EAdminPermission.Role_Delete]
                        },
                        loadChildren: () =>
                            import('@modules/admin/api-logs/api-logs.module').then((m) => m.ApiLogsModule)
                    }
                ]
            },
            // Exchange events routes
            {
                path: 'exchanges',
                data: {
                    breadcrumb: 'EXCHANGES',
                    disabled: true,
                    permissions: [EHospivillePermission.ExchangeEvent_Read]
                },
                loadChildren: () => import('@modules/exchanges/exchanges.module').then((m) => m.ExchangesModule)
            },
            // Homepage
            {
                path: 'home',
                data: {
                    permissions: [EHospivillePermission.Patient_Read]
                },
                loadChildren: () => import('@modules/home/home.module').then((m) => m.HomeModule)
            },

            // Pathways routes
            {
                path: 'pathways',
                data: {
                    breadcrumb: 'PATHWAYS',
                    disabled: true
                },
                children: [
                    // Reconciliations
                    {
                        path: 'list',
                        data: {
                            breadcrumb: 'PATHWAYS.LIST',
                            disabled: false,
                            permissions: [
                                EHospivillePermission.Pathway_Delete,
                                EHospivillePermission.Pathway_Read,
                                EHospivillePermission.Pathway_Update
                            ]
                        },

                        loadChildren: () =>
                            import('@modules/pathways/listing/pathways.module').then((m) => m.PathwaysModule)
                    },
                    // OCTAVE pathways
                    {
                        path: 'octave',
                        data: {
                            breadcrumb: 'PATHWAYS.OCTAVE-LIST',
                            disabled: false,
                            permissions: [EHospivillePermission.Pathway_Read]
                        },

                        loadChildren: () =>
                            import('@modules/pathways/listing/pathways.module').then((m) => m.PathwaysModule)
                    },
                    {
                        path: 'passerelle',
                        data: {
                            breadcrumb: 'PATHWAYS.PASSERELLE-LIST',
                            disabled: false,
                            permissions: [EHospivillePermission.Pathway_Passerelle_Access]
                        },

                        loadChildren: () =>
                            import('@modules/pathways/listing/pathways.module').then((m) => m.PathwaysModule)
                    },
                    // Export OCTAVE pathways
                    {
                        path: 'export',
                        data: {
                            breadcrumb: 'PATHWAYS.EXPORT',
                            disabled: false,
                            permissions: [EHospivillePermission.Pathway_Coordinate]
                        },

                        loadChildren: () =>
                            import('@modules/pathways/export/pathway-export.module').then((m) => m.PathwayExportModule)
                    },
                    // SHARED MEDICATION REVIEW pathways
                    {
                        path: 'shared-medication-review',
                        data: {
                            breadcrumb: 'PATHWAYS.SHARED-MEDICATION-REVIEW-LIST',
                            disabled: false,
                            permissions: [EHospivillePermission.Pathway_BPM_Access]
                        },

                        loadChildren: () =>
                            import('@modules/pathways/listing/pathways.module').then((m) => m.PathwaysModule)
                    }
                ]
            },
            // Patient route
            {
                path: 'patients',
                data: {
                    breadcrumb: 'PATIENTS',
                    disabled: false,
                    permissions: [
                        EHospivillePermission.Patient_Delete,
                        EHospivillePermission.Patient_Create,
                        EHospivillePermission.Patient_Read,
                        EHospivillePermission.Patient_Update,
                        EHospivillePermission.Patient_Transfer
                    ]
                },
                loadChildren: () => import('@modules/patient/patient.module').then((m) => m.PatientModule)
            },
            // Transferred patient route
            {
                path: 'patients-transferred',
                data: {
                    breadcrumb: 'PATIENTS_TRANSFERRED',
                    disabled: false,
                    permissions: [
                        EHospivillePermission.Patient_Delete,
                        EHospivillePermission.Patient_Create,
                        EHospivillePermission.Patient_Read,
                        EHospivillePermission.Patient_Update,
                        EHospivillePermission.Patient_Transfer
                    ]
                },
                loadChildren: () =>
                    import('@modules/patient/transferred/transferred-patient.module').then(
                        (m) => m.TransferredPatientModule
                    )
            },

            // Dashboard routes
            {
                path: 'dashboard',
                data: {
                    breadcrumb: 'DASHBOARD',
                    disabled: true
                },
                children: [
                    // Inbox
                    {
                        path: 'inbox',
                        data: {
                            breadcrumb: 'DASHBOARD.INBOX',
                            disabled: false,
                            permissions: [EHospivillePermission.Inbox]
                        },
                        loadChildren: () => import('@modules/dashboard/inbox/inbox.module').then((m) => m.InboxModule)
                    },
                    // Statistics routes
                    {
                        path: 'statistics',
                        data: {
                            breadcrumb: 'DASHBOARD.STATS',
                            disabled: false,
                            permissions: [EHospivillePermission.Stats_Export]
                        },
                        loadChildren: () =>
                            import('@modules/dashboard/statistics/statistics.module').then((m) => m.StatisticsModule)
                    }
                ]
            },
            // Notifications routes
            {
                path: 'notifications',
                data: {
                    breadcrumb: 'NOTIFICATIONS',
                    disabled: false
                },
                loadChildren: () =>
                    import('@modules/notifications/notifications.module').then((m) => m.NotificationsModule)
            },
            // Setting routes
            {
                path: 'settings',
                data: {
                    breadcrumb: 'SETTINGS',
                    disabled: true
                },
                children: [
                    // User
                    {
                        path: 'user',
                        data: {
                            breadcrumb: 'SETTINGS.USER',
                            disabled: false
                        },
                        loadChildren: () =>
                            import('@modules/settings/user/user-settings.module').then((m) => m.SettingsModule)
                    },
                    // Establishment
                    {
                        path: 'establishment',
                        data: {
                            breadcrumb: 'SETTINGS.ESTABLISHMENT',
                            disabled: false
                        },
                        loadChildren: () =>
                            import('@modules/settings/establishment/establishment.module').then(
                                (m) => m.EstablishmentModule
                            )
                    },
                    // Release notes
                    {
                        path: 'release-notes',
                        data: {
                            breadcrumb: 'SETTINGS.RELEASE-NOTES',
                            disabled: false
                        },
                        loadChildren: () =>
                            import('@modules/settings/release-notes/release-notes.module').then(
                                (m) => m.ReleaseNotesModule
                            )
                    },
                    // Changelog
                    {
                        path: 'changelog',
                        data: {
                            breadcrumb: 'SETTINGS.CHANGELOG',
                            disabled: false,
                            permissions: [EAdminPermission.ReleaseNote_Read]
                        },
                        loadChildren: () =>
                            import('@modules/admin/changelog/changelog.module').then((m) => m.AdminChangelogModule)
                    }
                ]
            }
        ]
    },
    { path: '**', redirectTo: defaultHome }
];
