<navigation
    class="md:w-auto fixed md:sticky left-0 top-0 bottom-0 z-999"
    [ngClass]="{
        'visible w-full': _navigationService.navigationOpened$ | async,
        'invisible w-auto': (_navigationService.navigationOpened$ | async) === false,
        'blur-sm pointer-events-none': !termsAccepted
    }"
></navigation>
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0" [ngClass]="{ 'blur-sm pointer-events-none': !termsAccepted }">
    <!-- Header -->
    <toolbar></toolbar>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="termsAccepted"></router-outlet>
    </div>
</div>

<!-- Terms of use update modal -->
<div
    *ngIf="!termsAccepted"
    tabindex="-1"
    aria-hidden="true"
    class="fixed flex justify-center items-center top-0 left-0 right-0 bottom-0 z-[1000] w-full p-4 overflow-x-hidden overflow-y-auto"
>
    <div class="flex flex-col bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="flex flex-0 items-center justify-between h-16 pl-6 sm:pl-8 bg-primary text-on-primary rounded-t-lg">
            <div class="text-lg font-medium">{{ 'TERMS_OF_USE.TITLE' | transloco }}</div>
        </div>

        <div class="p-6 space-y-2">
            <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                {{ 'TERMS_OF_USE.SUBTITLE' | transloco }}
            </p>
            <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                {{ 'TERMS_OF_USE.CONTENT' | transloco }}
                <a
                    class="text-primary-500 hover:underline"
                    href="https://www.mapui.fr/cgu_hospiville/"
                    target="_blank"
                    >{{ 'COMMONS.WORDS.HERE' | transloco }}</a
                >.
            </p>
        </div>

        <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
            <div class="flex justify-end mt-4 sm:mt-6">
                <button mat-flat-button type="submit" color="primary" (click)="validateTermsOfUse()">
                    {{ 'COMMONS.VERBS.ACCEPT' | transloco }}
                </button>
            </div>
        </div>
    </div>
</div>
