import { Component } from '@angular/core';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { NavigationService } from '@core/navigation/navigation.service';
import { UserService } from '@core/user/user.service';
import { Establishment } from '@mapuilabs/hv-interfaces';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
    activeEstablishment: Establishment;
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _navigationService: NavigationService,
        private _userService: UserService
    ) {
        this.activeEstablishment = this._userService.profile.establishment as Establishment;
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            this._navigationService.navigationOpened = !navigation.opened;
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this._navigationService.navigationOpened = true;
        this._navigationService.navigationAppearance =
            this._navigationService.navigationAppearance === 'default' ? 'dense' : 'default';
    }
}
